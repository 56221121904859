import { navigate } from "gatsby"
import { useEffect } from "react"
import * as React from "react"

const IndexPage: React.FunctionComponent = () => {
  useEffect(() => {
    navigate("/account")
  }, [])

  return <div />
}

export default IndexPage
